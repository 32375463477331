.App {
    text-align: center;
}

.position-relative-panel {
    height: 200px;
    width: 100%;


}

.position-relative span {

    width: 5em;
    height: 4em;
    color:white;
    background-color: red;
    border-radius: 0.25rem;
    padding-top: 20px; 
    text-align: center;
    justify-content: center;
    align-items: center;
}

/*
#root {
   transition: opacity 1s ease-in-out;
    height: 90vh; 
    background-image: url('./verbier.JPG');
    background-size: cover;
}
*/

.background-red {
 background-color: #953b39;   
}

.row {
    padding-top: 15px;
}

h5 {
    padding-top: 15px;
    text-align: center;
}


.fixed-bottom {
    min-width:100%;
    min-height: 10vh;
    color: white;
    background-color: red;
    font-size: .70em;
    padding: 20px;
}

.logo  {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;

}

#root.active {
  opacity: 1
}


.top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);


}

.left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);

}



.right {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);

}
.bottom {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.middle {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color:white;
    text-decoration: cornsilk
}


.modal-content {

    background-color: gray;
    color: white;
    height: 98vh;
}

.modal-content h1 {
    margin-top: 20px;
    text-align: center;
}

.carousel {
    padding-top: 20px;
    padding-bottom: 20px;
    height:460px;

}

.carousel-item {
    text-align: center;

}

.carousel-item img {
    margin: 0 auto;
    max-height: 100%;
    max-height: 430px;
}


.carousel-caption a, a:hover, a:focus, a:active {
    /*margin:auto;*/
    text-decoration: none;
    color: inherit; 
}

.center {
    text-align: center;
}

img {
width: 100%;
}


@media (max-width: 360px) {

    .carousel {
        padding-top: 20px;
        padding-bottom: 20px;
        height: 240px;

    }

    .carousel-item img {
        text-align: center;
        max-height: 200px;
    }

    .carousel-item div {
        margin: auto;
        max-width: 90%;
    }
    
}

